import { UbiButton } from 'components/common/elements/UbiButton';
import UbiTextInput from 'components/common/elements/UbiTextInput/UbiTextInput';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { Routes } from 'constants/navigation';
import { emailRegex } from 'constants/regexes';
import { useAuth } from 'context/authContext';
import { useSnackbar } from 'context/snackbarContext';
import { useLoginUser } from 'lib/api/mutations/users';
import { useNavigationHelpers } from 'lib/hooks/useNavigationHelpers';
import { useTranslation } from 'lib/hooks/useTranslation';
import { isTokenExpired } from 'lib/utils/auth';
import { isClient } from 'lib/utils/generic';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import LoadingBar from 'react-top-loading-bar';
import sharedStyle from 'styles/authShared.module.scss';
import style from './Login.module.scss';

interface LoginFormPayload {
    email: string;
    password: string;
}

const initialFormValues = {
    email: '',
    password: '',
};

const LoginRight = () => {
    const { handleSubmit, control, formState } = useForm({
        defaultValues: initialFormValues,
    });
    const { loginUser } = useLoginUser();
    const { dispatch, isLoggedIn } = useAuth();
    const { dispatch: snackbarDispatch } = useSnackbar();
    const router = useRouter();
    const t = useTranslation();
    const loadingRef = useRef<any | unknown>(null);
    const { getLocalizedRoute } = useNavigationHelpers();

    useEffect(() => {
        if (isClient) {
            if (isLoggedIn() && !isTokenExpired()) router.push(Routes.Dashboard);
        }
    }, []);

    const handleLogin = (data: LoginFormPayload) => {
        loadingRef?.current?.continuousStart();
        loginUser({ variables: data })
            .then(({ data }) => {
                dispatch({
                    type: 'login',
                    payload: {
                        ...data.signin,
                    },
                });
                loadingRef?.current?.complete();
                router.push('/dashboard');
            })
            .catch((e: Error) => {
                loadingRef?.current?.complete();
                snackbarDispatch({
                    type: 'setMessage',
                    payload: e.name === 'TypeError' ? t.errors.loginForm.global.default : e.message,
                });
                snackbarDispatch({
                    type: 'setVisible',
                    payload: true,
                });
            });
    };

    return (
        <>
            <div>
                <LoadingBar color="#4285DE" height={1} ref={loadingRef} shadow={true} />
                <h1 className={sharedStyle.mainHeading}>{t.login.title}</h1>
                <h2 className={sharedStyle.subHeading}>{t.login.subtitle}</h2>
                <form className={style.loginForm} onSubmit={handleSubmit(handleLogin)}>
                    <UbiTextInput
                        formErrorTObject={t.errors.loginForm}
                        fieldError={formState.errors?.email}
                        control={control}
                        rules={{ required: true, pattern: emailRegex }}
                        name="email"
                        classNames={style.loginInput}
                        label={t.login.inputs.email.label}
                    />
                    <UbiTextInput
                        type="password"
                        formErrorTObject={t.errors.loginForm}
                        fieldError={formState.errors?.password}
                        control={control}
                        rules={{ required: true }}
                        name="password"
                        classNames={style.loginInput}
                        label={t.login.inputs.password.label}
                    />
                    <div className={style.additionalActions}>
                        <div className={style.forgotPassword}>
                            <Link href={getLocalizedRoute(Routes.RecoverPassword)}>{t.login.forgetPassword}</Link>
                        </div>
                    </div>
                    <div className={style.loginBtn}>
                        <UbiButton type="submit" variant="contained">
                            {t.login.signInBtn}
                        </UbiButton>
                    </div>
                    <div className={sharedStyle.questions}>
                        {t.login.dontHaveAccount} <Link href={getLocalizedRoute(Routes.Signup)}>{t.login.signup}</Link>
                    </div>
                </form>
            </div>
        </>
    );
};

const Login = () => {
    const t = useTranslation();

    return (
        <>
            <Head>
                <title>{t.seo.login}</title>
                <meta property="og:title" content={t.seo.login} />
            </Head>
            <AuthLayout contentRight={<LoginRight />} />
        </>
    );
};

export default Login;
