import { gql, useMutation } from '@apollo/client';

const INVITE_USER = gql`
    mutation InviteUser($email: String!, $name: String!, $organization: String!, $role: String!) {
        inviteUser(payload: { email: $email, name: $name, organization: $organization, role: $role }) {
            success
        }
    }
`;

const RESEND_INVITE = gql`
    mutation resendInvite($user: String!, $email: String!, $name: String!, $organization: String!, $role: String!) {
        resendInvite(payload: { email: $email, name: $name, organization: $organization, role: $role, user: $user }) {
            success
        }
    }
`;

const DELETE_INVITE = gql`
    mutation deleteUser($organization: String!, $user: String!) {
        deleteInvitation(payload: { organization: $organization, user: $user }) {
            success
        }
    }
`;

const UPDATE_INVITED_USER = gql`
    mutation updateInvited($email: String!, $name: String!, $organization: String!, $role: String!, $user: String!) {
        updateInvited(payload: { email: $email, name: $name, organization: $organization, role: $role, user: $user }) {
            success
        }
    }
`;

const SET_USER_GOAL = gql`
    mutation setUserGoal($goal: UserGoalEnum!) {
        setUserGoal(payload: { goal: $goal }) {
            success
        }
    }
`;

const LOGIN_USER = gql`
    mutation SignIn($email: String!, $password: String!) {
        signin(payload: { email: $email, password: $password }) {
            expiresIn
            refreshToken
            token
            user {
                email
                name
                _id
                verified
                organizations {
                    _id
                    role
                    organization {
                        _id
                        vat
                        status
                        trial
                        legalName
                        subscriptionEnds
                        subscription {
                            name
                            data {
                                trustBadge
                                users
                                credits
                                apiAccess
                                priceMonthly
                                trial
                                dailyAutomaticScans
                                personalOnboarding
                                priceYearly
                            }
                        }
                        domains {
                            _id
                            domain
                        }
                    }
                }
            }
        }
    }
`;

export const UseInviteUser = () => {
    const [Invite] = useMutation(INVITE_USER);
    return {
        Invite,
    };
};

export const UseResendInvite = () => {
    const [ResendInvite] = useMutation(RESEND_INVITE);
    return {
        ResendInvite,
    };
};

export const UseDeleteInvite = () => {
    const [DeleteInvite] = useMutation(DELETE_INVITE);
    return {
        DeleteInvite,
    };
};

export const UseUpdateInvitedUser = () => {
    const [UpdateInvited] = useMutation(UPDATE_INVITED_USER);
    return {
        UpdateInvited,
    };
};

export const UseSetUserGoal = () => {
    const [SetUserGoal] = useMutation(SET_USER_GOAL);
    return {
        SetUserGoal,
    };
};

export const useLoginUser = () => {
    const [loginUser] = useMutation(LOGIN_USER);

    return {
        loginUser,
    };
};
